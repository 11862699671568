<template>
  <div class="SuspensionFunction">
    <div class="SuspensionFunction-title">
      <div class="title">高级功能设置-悬浮功能</div>
    </div>
    <div class="SuspensionFunction-content">
        <div class="pingmu">
          <div style="margin-top: 400px">
            <div v-for="img in data" style="padding-right: 15px">
              <img :src="img.icon" style="width: 50px;margin-top: 20px">
            </div>
          </div>
        </div>
      <div style="width: 70%;margin-left: 30px">
        <a-table :columns="columns" :data-source="data" :row-key="record => record.id" :pagination="false">
          <span slot="icon" slot-scope="text, record, index">
          <div v-if="!record.isTableShow"
               style="display: flex;justify-content: center;position: relative;width: 120px;height: 120px">
            <div class="uploadImgs" v-if="!record.icon" @click="openModelPic(record)">
<!--              <input-->
<!--                  style="width: 120px;height: 120px;opacity: 0;"-->
<!--                  accept="image/*"-->
<!--                  type="file"-->
<!--                  @change="upload('upload_img' + record.id,record,record.id + 'icon')"-->
<!--                  :id="'upload_img' + record.id" />-->
            </div>
            <div v-if="record.icon" class="upload_image" style="position: relative">
              <img
                  style="width: 100px;height: auto;margin-top: 8px"
                  :src="record.icon"/>
              <a-icon @click="()=>{record.icon = ''}" v-if="index !== 0" class="icon" type="close-circle" />
            </div>
          </div>
        </span>
          <template
              v-for="col in ['url']"
              :slot="col"
              slot-scope="text, record, index"
          >
            <div :key="col">
              <a-input
                  placeholder="请输入链接"
                  v-if="record.editable"
                  style="margin: -5px 0"
                  :value="text"
                  @change="e => handleChange(e.target.value, record.id, col)"
              />
              <template v-else>
                <span v-if="record.url === '系统功能' || !record.url">{{record.url?record.url:'未设置'}}</span>
                <a v-else @click="openLink(record.url)">{{ text}}</a>
              </template>
            </div>
          </template>
          <span slot="isOpen" slot-scope="text, record,index">
            <a-switch
                v-if="!record.isShowSwitch"
                :disabled="index === 0"
                style="margin-right: 10px"
                v-model="record.isOpen ? true:false"
                @change="switchChange(record,record.isOpen ? false:true)"
                checked-children="开"
                un-checked-children="关"
                default-checked />
          </span>
          <template slot="action" slot-scope="text, record, index">
            <div class="editable-row-operations">
            <span v-if="record.editable">
              <a @click="() => save(record.id,record)">保存</a>
              <a-popconfirm
                  style="margin-left: 5px"
                  title="是否确认取消?"
                  @confirm="() => cancel(record.id,record)">
                <a>取消</a>
              </a-popconfirm>
            </span>
              <span v-else>
                 <a v-if="index !== 0" @click="() => edit(record.id)">设置</a>
            </span>
            </div>
          </template>
        </a-table>
      </div>
    </div>
    <pictureDialog
        :visible="visiblePic"
        v-on:closeMain="closeMainPic"
        v-on:checkList="checkListPic">
    </pictureDialog>
  </div>
</template>

<script>
import {update} from "@/utils/update";
import {getStreamFunXf, putStreamFunXf, putStreamFunc, streamFuncById} from "@/service/liveManage";
import pictureDialog from "@/components/Material/pictureDialog";
const columns = [
    {
    title: '图片',
    dataIndex: 'icon',
    key: 'icon',
    align:'center',
    width:'180px',
    scopedSlots: { customRender: 'icon' },
  },{
    title: '外链',
    dataIndex: 'url',
    key: 'url',
    align:'center',
    ellipsis: true,
    width:'20%',
    scopedSlots: { customRender: 'url' },
  },{
    title: '状态',
    dataIndex: 'isOpen',
    key: 'isOpen',
    align:'center',
    width:'100px',
    scopedSlots: { customRender: 'isOpen' },
  },{
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    align:'center',
    width:'150px',
    scopedSlots: { customRender: 'action' },
  },
];
const data = [];
export default {
  name: "SuspensionFunction",
  components:{
    pictureDialog
  },
  data(){
    return{
      isShow:false,
      visiblePic:false,
      relayImg:'',
      link:'',
      columns,
      data,
      PicList:[],
    }
  },
  computed:{
    funList(){
      return this.$store.state.funList;
    },
    streamIds(){
      return this.$store.state.streamId;
    }
  },
  created() {
    console.log('streamId',this.streamIds)
    this.getContent()
  },
  methods:{
    openModelPic() {
      this.visiblePic = true;
    },
    closeMainPic(val) {
      this.visiblePic = val;
    },
    checkListPic(val){
      this.PicList = val[0].url;
      this.data[1].icon = this.PicList;
    },
    openLink(url){
      window.open(url)
    },
    save(id,row) {
      const newData = [...this.data];
      const newCacheData = [...this.cacheData];
      const target = newData.find(item => id === item.id);
      const targetCache = newCacheData.find(item => id === item.id);
      if (target && targetCache) {
        delete target.editable;
        this.data = newData;
        Object.assign(targetCache, target);
        this.cacheData = newCacheData;
        //保存修改
        let data = {
          "streamId":this.streamIds,//直播间id
          "url":target.url,
          "icon":target.icon,
          "isOpen":0 //1开启  0关闭
        }
        this.putList(data);
      }
      this.editingKey = '';
    },
    edit(id) {
      const newData = [...this.data];
      const target = newData.find(item => id === item.id);
      this.editingKey = id;
      if (target) {
        target.editable = true;
        this.data = newData;
      }
    },
    cancel(id,record) {
      if(record.isAddSystem){ //新增-取消删除当前数组
        this.data.splice(this.data.length-1,1)
      }else {
        const newData = [...this.data];
        const target = newData.find(item => id === item.id);
        this.editingKey = '';
        if (target) {
          Object.assign(target, this.cacheData.find(item => id === item.id));
          delete target.editable;
          this.data = newData;
        }
      }
    },
    handleChange(value, id, column) {
      const newData = [...this.data];
      const target = newData.find(item => id === item.id);
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    async upload(inputId,row,idImg){
      let inputDOM = document.getElementById(inputId).files[0]
      if(inputDOM.size < 2086666.24 ){
        const image = await update(inputDOM)
        if(image.code === 0){
          row.icon = image.data.url;
        } else{
          this.$message.warning('上传失败'+image.message)
        }
      } else{
        this.$message.warning('仅上传2M内图片')
      }
    },
    // 修改列表
    async putList(data) {
      const response = await putStreamFunXf(data)
      if(response.code === 200){
        this.$message.success("修改成功！");
        await this.getContent();
      }else {
        this.$message.warning(response.message);
      }
    },
    //通过funId获取内容
    async getContent() {
      const response = await getStreamFunXf(this.streamIds);
      if(response.code === 200){
        if(response.data){
          this.data = [
            {
              id:1,
              url:'系统功能',
              isOpen:1,
              icon:'https://yichi-1252497858.cos.ap-guangzhou.myqcloud.com/images_convention/90da67eb-5164-425a-9e4e-2fdf9d5a6649.png',
            },
            {
              id:2,
              url:response.data.url,
              isOpen:response.data.isOpen,
              icon:response.data.icon,
            },
          ];
        }else {
          this.data = [
            {
              id:1,
              url:'系统功能',
              isOpen:1,
              icon:'https://yichi-1252497858.cos.ap-guangzhou.myqcloud.com/images_convention/90da67eb-5164-425a-9e4e-2fdf9d5a6649.png',
            },
            {
              id:2,
              url:'',
              isOpen:0,
              isTableShow:false,
              isShowSwitch:true,
              icon:'',
              editable:true,
            },
          ]
        }

        this.cacheData = this.data.map(item => ({ ...item }));
      }
    },
    switchChange(row,isOpen){
      this.isShow = isOpen;
      let data = {
        "streamId":this.streamIds,//直播间id
        "url":row.url,
        "icon":row.icon,
        "isOpen":isOpen ? 1:0  //1开启  0关闭
      }
      this.putList(data);
    },
    async surfacePlotBtn() {
      let inputDOM = this.$refs.surfacePlot.files[0];
      //判断图片大小
      if (inputDOM.size < 1048576) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.relayImg = image.data.url
          this.$message.success("上传成功")
        } else {
          this.$message.warning("上传失败")
        }

      } else {
        this.$message.warning("请上传小于1M的图片！")
      }
      document.getElementById('surfacePlot').value = null
    },
    delCover(){
      this.relayImg = '';
    },
  }
}
</script>

<style scoped lang="scss">

.SuspensionFunction{
  width: 100%;
  //border-left: 1px solid #d9d9d9;
  .SuspensionFunction-title{
    height: 50px;line-height: 50px;
    border-bottom: 1px solid #F5F5F5;
    display: flex;
    justify-content: space-between;
    .title{
      font-weight: bold;
      margin-left: 10px;
    }
  }
  .SuspensionFunction-content{
    padding: 20px;
    display: flex;
    .pingmu{
      width: 320px;
      text-align: right;
      height: 650px;
      background-color: #f5f5f5;
      border: #DDDDDD 2px dashed;
      border-radius: 10px;
    }
    .uploadImgs{
      border: #DDDDDD 2px dashed;
      width: 120px;
      height: 120px;
      background-image: url("../../assets/add.png");
      background-size:100% 100%;
      background-repeat:no-repeat;
      cursor: pointer;
    }
    .upload_image{
      border: #DDDDDD 2px dashed;
      width: 120px;
      height: 120px;
      text-align: center;
    }
    .icon{
      color: red;
      cursor: pointer;
      position: absolute;
      top: 5px;
      right: 5px;
    }
    .uploadImg{
      border: #DDDDDD 2px dashed;
      width: 280px;
      min-height: 700px;
      border-radius: 5px;
      text-align: center;
      padding-top: 30px;
      background-color: #fafafa;
    }
    .uploadImg_img{
      position: absolute;
      top: 0;
      width: 100%;
    }
    .upImg{
      width: 100%;
      height: 100%;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;

    }
  }
}
</style>
